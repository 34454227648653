import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import Logo from "./Logo";
import { Div } from "./Markup";
import { BLACK } from "../styles/colors";
import MenuMobile from "@components/MenuMobile";
import Social from "@components/Social";
import { HamburgerSliderReverse } from "react-animated-burgers";

export const HEADER_HEIGHT = 64;

const Header = ({ location }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location.pathname]);

  return (
    <header
      css={css`
        position: fixed;
        height: ${HEADER_HEIGHT}px;
        border-bottom: 2px solid ${BLACK};
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: white;
        z-index: 20;
        padding: 0px 24px;
        box-sizing: border-box;
        background-color: white;
        z-index: 200;

        @media (min-width: 992px) {
          padding: 0px 48px;
        }
      `}
    >
      <Link to="/">
        <Logo />
      </Link>
      <Div
        css={css`
          @media (max-width: 991px) {
            display: none;
          }
        `}
      >
        <Social />
      </Div>
      <Div
        flex
        css={css`
          button {
            padding: 0px;
            margin-top: 3px;
          }
          @media (min-width: 992px) {
            display: none;
          }
        `}
      >
        <HamburgerSliderReverse
          isActive={isMenuOpen}
          buttonWidth={24}
          onClick={() => {
            setIsMenuOpen(!isMenuOpen);
          }}
        />
        <MenuMobile isOpen={isMenuOpen} />
      </Div>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
